<template>
  <PageContainer class="has-tabs">
    <template slot="title">
      <!-- 标题栏 -->
      <pageTitle pageTitle="合规风险设置">
        <router-link class="link-text" tag="a" target="_blank" to="/setting/protocol">
          查看账号下合规风险设置
        </router-link>
        <HelpPopover />
      </pageTitle>
    </template>
    <!-- 内容区 -->
    <template slot="content">
      <!-- tabs -->
      <ComplianceRisk :webinarState="webinarState"></ComplianceRisk>
      <begin-play
        :webinarId="$route.params.str"
        v-if="$route.query.type != 5 && webinarState != 4"
      ></begin-play>
    </template>
  </PageContainer>
</template>

<script>
  import PageContainer from '@/components/PageContainer';
  import PageTitle from '@/components/PageTitle';
  import ComplianceRisk from '@/components/ComplianceRisk';
  import beginPlay from '@/components/beginBtn';
  import { sessionOrLocal } from '@/utils/utils';
  export default {
    components: {
      PageContainer,
      PageTitle,
      ComplianceRisk,
      beginPlay
    },
    data() {
      return {
        activeTab: '1',
        functionOpen: false,
        webinarState: sessionOrLocal.get('webinarState') // 活动状态
      };
    },
    created() {
      this.activeTab = this.$route.query.tab || '1';
    },
    methods: {
      settingChange() {}
    }
  };
</script>

<style scoped lang="less">
  .link-text {
    color: #3562fa;
    cursor: pointer;
    font-size: 14px;
  }
</style>
